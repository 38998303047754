//
// components.scss
//

.btn {
  padding: 12px 28px;
  font-size: 15px;
  border-radius: 7px;
  font-weight: $font-weight-semibold;
  transition: all 0.5s ease;
  position: relative;
  &:focus {
    box-shadow: none;
  }
}
.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}

@each $color, $value in $theme-colors {
  .btn-#{$color} {
    background: #{$value};
    border-color: #{$value} !important;
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: darken($value, 4%);
      border-color: darken($value, 4%);
      box-shadow: 0px 8px 20px -6px rgba($value, 0.6);
    }
  }
  .btn-outline-#{$color} {
    color: #{$value};
    border-color: #{$value};
    &:hover,
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled):active:focus,
    .open > .dropdown-toggle.btn-primary {
      background: $value;
      border-color: $value;
      box-shadow: 0px 8px 20px -6px rgba($value, 0.6);
    }
  }
}

.btn {
  &.btn-outline-white,
  &.btn-outline-light {
    &:focus,
    &:active,
    &:hover,
    &.active {
      color: $dark;
    }
  }
  &.btn-outline-primary,
  &.btn-outline-dark,
  &.btn-outline-success {
    &:focus,
    &:active,
    &:hover,
    &.active {
      color: $white !important;
    }
  }
}
