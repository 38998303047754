//
// general.scss
//

body {
  font-family: $font-family-base;
  color: $dark;
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-base;
  font-weight: 700;
  line-height: 1.3;
}

a {
  text-decoration: none;
  outline: none;
}

p {
  line-height: 1.6;
}

html {
  scroll-padding-top: 50px;
  scroll-behavior: smooth;
}

.row > * {
  position: relative;
}