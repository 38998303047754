//
// testimonial.scss
//

.testimonial-box {
  .testimonial-content {
    max-width: 650px;
    margin: 0 auto;
    padding: 25px 20px;
    font-size: 18px;
    background: rgba($light, 0.2);
    position: relative;
    &::after { 
      content: "";
      border: 16px solid transparent;
      border-top-color: rgba($light, 0.2);
      height: 20px;
      position: absolute;
      bottom: -32px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.carousel-indicators {
  align-items: center;
  position: relative;
  button {
    width: 80px !important;
    height: 80px !important;
    border-radius: 50%;
    overflow: hidden;
  }
  .active {
    opacity: 1;
    border: 4px solid $primary;
    z-index: 3;
  }
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-color: $primary;
  background-size: 100% 40%;
  border-radius: 50%;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}
